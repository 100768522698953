<template>
  <div class="our-story">
    <v-row>
      <div cols="12" style="overflow:hidden" class="container_">
        <img
          src="../../assets/images/Rectangle 1212 (1)-compressed.jpg"
          alt=""
          height="500"
          width="100%"
          class="image"
          :class="$vuetify.breakpoint.xs ? 'top-img' : ''"
          :style="$vuetify.breakpoint.xs ? 'object-fit:cover' : ''"
        />
        <div class="overlay">
          <h2>About Us</h2>
        </div>
      </div>
      <v-col cols="12">
        <v-row cols="12" sm="12" class="padding">
          <!-- <v-row class="py-16"> -->
          <!-- <v-col cols="12" sm="6" class="py-16">
            <h2 class="pb-8">Our Story</h2>
            <p>
              Gricd is a cold chain solutions provider specializing in the field
              of temperature-controlled products, temperature monitoring
              solutions, and cold chain services. We focus on understanding the
              unique challenges experienced by each of the markets we serve.
              Our IoT-enabled devices have enabled us to provide problem-solving
              products and services to clients within and outside Nigeria, which
              include pharmaceutical manufacturers and agricultural companies.
              Our major goal is to help our clients maintain the integrity of
              their products.
            </p>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            style="align-items: center;
    display: flex"
          >
            <img
              src="../../assets/images/Rectangle 1225.png"
              alt=""
              width="100%"
              height="300"
            />
          </v-col> -->
          <!-- </v-row> -->
          <v-col cols="12" class="py-10">
            <v-spacer></v-spacer>
            <h2 style="color:#28A84A" class="text-center pb-8">
              Our Story
            </h2>
            <p
              class="story-p mx-auto"
              :class="$vuetify.breakpoint.xs ? 'text-left' : 'text-center'"
            >
              Gricd is a cold chain solutions provider specializing in the field
              of temperature-controlled products, temperature monitoring
              solutions, and cold chain services. We focus on understanding the
              unique challenges experienced by each of the markets we serve. Our
              IoT-enabled devices have enabled us to provide problem-solving
              products and services to clients within and outside Nigeria, which
              include pharmaceutical manufacturers and agricultural companies.
              Our major goal is to help our clients maintain the integrity of
              their products.
            </p>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>

        <v-row class="padding" style="background: rgba(242, 245, 247, 0.46);">
          <v-col cols="12" class="challenge-heading py-10">
            <v-spacer></v-spacer>
            <h2 style="color:#28A84A" class="text-center pb-8">
              Challenges with coldchain products
            </h2>
            <p
              :class="$vuetify.breakpoint.xs ? 'text-left' : 'text-center'"
              class="story-p mx-auto"
            >
              We discovered that each hand-off in the cold chain line opens the
              door for risk. Assumption of product integrity is not enough,
              without a reliable and consistent temperature monitoring and data
              logger, especially during storage, transit, and distribution to
              the final consumer.
            </p>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>

        <v-row class="padding">
          <v-row class="py-12" :class="$vuetify.breakpoint.xs ? 'px-3' : ''">
            <v-col cols="12" sm="6">
              <div>
                <div class="circle"></div>
                <h3 class="" style="position: relative; bottom: 27px;">
                  The last mile problem
                </h3>
              </div>
              <p>
                From coolers that store medications and enable vaccination
                outreaches in remote locations, to cooling vans that transport
                harvest from the farm to the consumer, the cold chain is crucial
                part of our lives from production to the last mile. But with
                challenges such as unreliable power supply or using coolers and
                ice packs in humid weather, the cold chain needs reliable
                autonomous cold chain solutions for the last mile.
              </p>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="square"></div>
              <h3 class="" style="position: relative; bottom: 27px;">
                An IoT solution
              </h3>
              <p>
                Starting from prototyping with a local gourd pot, we built a
                smart cold chain box that could help people keep their
                temperature sensitive commodities at regulated temperatures
                during storage and transportation.
              </p>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>

    <!-- apparatus equipment section -->
    <div class="py-12" style="background: rgba(242, 245, 247, 0.46);">
      <div class="top-apparatus text-center">
        <h6>What we did</h6>
        <h2 class="py-6 mx-2">Our ColdChain Progress</h2>
      </div>

      <v-row class="px-12 py-5" style="justify-content:space-evenly">
        <div class="apparatus-card mt-10" v-for="(m, i) in icon" :key="i">
          <img
            :src="m.img"
            style="height: 4rem;max-width: 4rem;"
            alt=""
            class="mb-4"
          />
          <p class="p1">{{ m.p1 }}</p>
          <p class="p2">{{ m.p2 }}</p>
          <p class="text-center">
            {{ m.content }}
          </p>
        </div>
      </v-row>
    </div>

    <div :class="$vuetify.breakpoint.xs ? 'pt-16' : ''" style="width:100%">
      <div
        style="position:relative"
        :style="$vuetify.breakpoint.xs ? 'padding-bottom: 13rem' : ''"
      >
        <img
          src="../../assets/background/Group 1934@3x.png"
          alt="partners"
          width="100%"
        />
        <div
          class="pt-12"
          style="height: 7rem; position: absolute;top: 0px;width: 100%;"
        >
          <div class="heading-title">
            <span class="stroke">
              <div class="line"></div>
              <div class="line2"></div>
            </span>
            <h2
              style="color:#28A84A; font-size:34px"
              class="text-center"
              :class="$vuetify.breakpoint.mdAndDown ? 'mb-6' : ''"
            >
              Our Clients
            </h2>
          </div>

          <v-row
            style="flex-flow: row;overflow-x: auto;"
            :class="$vuetify.breakpoint.mdAndDown ? 'mx-4' : 'mx-16'"
            id="container__"
          >
            <span>
              <img
                :src="m.img"
                alt=""
                v-for="(m, i) in clientImg"
                :key="i"
                :style="
                  $vuetify.breakpoint.mdAndDown
                    ? 'width: 8rem;height: 8rem; object-fit:contain'
                    : 'width: 10rem;height: 10rem; object-fit:contain'
                "
                :class="i > 8 ? 'mr-12' : ''"
              />
            </span>
          </v-row>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import cl1 from '../../assets/clients/image 25@3x.png';
import cl2 from '../../assets/clients/image 26@3x.png';
import cl3 from '../../assets/clients/image 42.png';
import cl4 from '../../assets/clients/image 28 (1).png';
import cl5 from '../../assets/clients/image 41.png';
import cl6 from '../../assets/clients/image 31 (1).png';
import cl7 from '../../assets/clients/Group 2023.png';
import cl8 from '../../assets/clients/image 38.png';
import cl9 from '../../assets/clients/Group 2022.png';
import cl10 from '../../assets/clients/image 25.png';
import cl11 from '../../assets/clients/image 30.png';
import cl12 from '../../assets/clients/image 34 (1).png';
import cl13 from '../../assets/clients/image 44.png';
import cl14 from '../../assets/clients/nphcda_small_logo.jpeg';
import cl16 from '../../assets/clients/Vaccipharm Logo.png';
import cl17 from '../../assets/clients/mPharma .jpeg';
import cl18 from '../../assets/clients/ISN logo.jpeg';

import ic1 from '../../assets/about icon/Group 1422.png';
import ic2 from '../../assets/about icon/Group 1419.png';
import ic3 from '../../assets/about icon/Group 1421.png';


export default {
  name: 'About',
  data: () => ({
    clientImg: [
      { img: cl1 },
      { img: cl2 },
      { img: cl3 },
      { img: cl4 },
      { img: cl5 },
      { img: cl6 },
      { img: cl7 },
      { img: cl8 },
      { img: cl9 },
      { img: cl10 },
      { img: cl16 },
      { img: cl17 },
      { img: cl18 },
      { img: cl11 },
      { img: cl12 },
      { img: cl13 },
      { img: cl14 },
    ],
    icon: [
      {
        img: ic1,
        p1: '200 plasma',
        p2: 'Plasma Sample',
        content:
          'We have helped deliver plasma samples with notable health institutions.',
      },
      {
        img: ic2,
        p1: '800 blood',
        p2: 'Blood Sample',
        content:
          'We have worked with health institutions to help deliver blood samples.',
      },
      {
        img: ic3,
        p1: '218 vials',
        p2: 'Vaccine vials',
        content: 'We have moved several vaccine vials to last mile locations.',
      },
    ],
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Our Story',
                item: 'https://gricd.com/about/our-story',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #141414;
}
h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 140%;
  color: #212529;
}
.challenge-heading {
  background: rgba(242, 245, 247, 0.46);
}
h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 140%;
  color: #212529;
}
.circle {
  clip-path: circle(50% at 50% 50%);
  background: #b5ccbb;
  height: 28px;
  width: 28px;
  position: relative;
  right: 7px;
}
.square {
  width: 28px;
  height: 28px;
  background: #f1dbc1;
  transform: rotate(-42.61deg);
  position: relative;
  right: 7px;
}
.top-img {
  object-fit: cover;
  /* height: 30rem; */
  width: 50rem;
  position: relative;
  right: 18rem;
}
.padding {
  padding: 2rem 6rem;
  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
  @media (max-width: 1290px) and (min-width: 769px) {
    padding: 2rem 4.5rem;
  }
}
::-webkit-scrollbar {
  // width: 2px;
  height: 0%;
}

.container_ {
  position: relative;
  width: 100%;
}

.overlay {
  position: absolute;
  bottom: 0.5rem;
  background: black;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    color: white;
  }
}

.container_ .overlay {
  opacity: 1;
}

.apparatus-card {
  width: 18rem;
  // border: solid;
  height: 15rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
h6 {
  font-size: 1rem;
  color: #28a84a;
  font-weight: 500;
}
.p1 {
  font-size: 1rem;
  font-weight: 500;
}
.p2 {
  font-size: 1rem;
  font-weight: 500;
  color: #28a84a;
}
.story-p {
  width: 74%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
</style>
